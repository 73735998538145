@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 224 71.4% 4.1%;

        --card: 0 0% 100%;
        --card-foreground: 224 71.4% 4.1%;

        --popover: 0 0% 100%;
        --popover-foreground: 224 71.4% 4.1%;

        --primary: 220.9 39.3% 11%;
        --primary-foreground: 210 20% 98%;

        --secondary: 220 14.3% 95.9%;
        --secondary-foreground: 220.9 39.3% 11%;

        --muted: 220 14.3% 95.9%;
        --muted-foreground: 220 8.9% 46.1%;

        --accent: 220 14.3% 95.9%;
        --accent-foreground: 220.9 39.3% 11%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 20% 98%;

        --border: 220 13% 91%;
        --input: 220 13% 91%;
        --ring: 224 71.4% 4.1%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 224 71.4% 4.1%;
        --foreground: 210 20% 98%;

        --card: 224 71.4% 4.1%;
        --card-foreground: 210 20% 98%;

        --popover: 224 71.4% 4.1%;
        --popover-foreground: 210 20% 98%;

        --primary: 210 20% 98%;
        --primary-foreground: 220.9 39.3% 11%;

        --secondary: 215 27.9% 16.9%;
        --secondary-foreground: 210 20% 98%;

        --muted: 215 27.9% 16.9%;
        --muted-foreground: 217.9 10.6% 64.9%;

        --accent: 215 27.9% 16.9%;
        --accent-foreground: 210 20% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 20% 98%;

        --border: 215 27.9% 16.9%;
        --input: 215 27.9% 16.9%;
        --ring: 216 12.2% 83.9%;
    }
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
    }
}

* {
    margin: 0;
    padding: 0;
    outline: none;
}

@media screen and (max-width: 1300px) {
    div.dashboard {
        display: none;
    }
}

@media screen and (min-width: 1300px) {
    div.dashnav {
        display: none;
    }
}

.opacityAnim {
    animation: splash 0.5s
}

@keyframes splash {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@layer base {
    html {
        font-family: "Inter", system-ui, sans-serif;

    }
}

@layer components {
    .itemHover {
        @apply hover:bg-gray-600 hover:bg-opacity-5 hover:shadow-sm rounded-lg;
    }

    .tableCellHover {
        @apply cursor-pointer hover:text-marcoDarkGreen hover:font-bold;
    }

    .tableSkeletonCell {
        @apply w-full h-6 bg-gray-600 bg-opacity-5 rounded-md
    }

    .xDefault {
        @apply hover:bg-[#F5F5F5] py-1 px-1 h-[30px] w-[30px] stroke-[2.3px] rounded-full cursor-pointer
    }

    .skeleton-header {
        @apply animate-pulse bg-gray-600 bg-opacity-5 rounded-md h-8
    }

    .skeleton-text-md {
        @apply animate-pulse bg-gray-600 bg-opacity-5 rounded-md h-6
    }

    .skeleton-text-sm {
        @apply animate-pulse bg-gray-600 bg-opacity-5 rounded-md h-5
    }



    .middleWidth {
        @apply w-[70%]
    }

    .leftSection {
        @apply w-[65%]
    }

    .leftSmallSection {
        @apply w-[30%]
    }

    .rightSection {
        @apply w-[]
    }
}